import React from 'react';

const Privacy = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white text-gray-800">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-sm text-gray-500 mb-6">Effective Date: 12/24/2023</p>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
        <p>
          Welcome to Appliance Repair Service in Houston ("we," "our," or "us"). Your privacy is important to us. This Privacy Policy explains how we
          collect, use, and protect your personal information when you visit our website, use our services, or otherwise interact with us.
        </p>
        <p>
          By using our website or services, you agree to the terms of this Privacy Policy.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">2. Information We Collect</h2>
        <ul className="list-disc pl-6">
          <li>
            <strong>Personal Information:</strong> Name, email address, phone number, billing and shipping addresses, and payment
            details when you make a purchase or contact us.
          </li>
          <li>
            <strong>Usage Data:</strong> Information about how you interact with our website, including your IP address, browser type,
            pages visited, and time spent on pages.
          </li>
          <li>
            <strong>Cookies and Tracking Technologies:</strong> Data collected through cookies and similar technologies to improve user
            experience and analyze website performance.
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">3. How We Use Your Information</h2>
        <p>We use your information to:</p>
        <ul className="list-disc pl-6">
          <li>Provide and improve our services.</li>
          <li>Process payments and fulfill orders.</li>
          <li>Respond to inquiries and provide customer support.</li>
          <li>Send promotional materials and updates (if you opt-in).</li>
          <li>Ensure website security and prevent fraud.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">4. Sharing Your Information</h2>
        <p>We do not sell your personal information. However, we may share your information with:</p>
        <ul className="list-disc pl-6">
          <li>
            <strong>Service Providers:</strong> Third-party vendors who help us operate our website, process payments, or provide services.
          </li>
          <li>
            <strong>Legal Obligations:</strong> Authorities or parties as required by law, such as for legal processes or fraud prevention.
          </li>
          <li>
            <strong>Business Transfers:</strong> In case of a merger, acquisition, or sale of assets.
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">5. Your Rights</h2>
        <p>You have the right to:</p>
        <ul className="list-disc pl-6">
          <li>Access, update, or delete your personal information.</li>
          <li>Opt-out of receiving promotional communications.</li>
          <li>Restrict the processing of your data in certain circumstances.</li>
          <li>Lodge a complaint with a data protection authority.</li>
        </ul>
        <p>To exercise your rights, contact us at appliance-repair-experts@gmail.com</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">6. Data Security</h2>
        <p>
          We implement appropriate technical and organizational measures to protect your personal information from unauthorized access,
          alteration, disclosure, or destruction. However, no method of transmission over the internet is 100% secure.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">7. Third-Party Links</h2>
        <p>
          Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these sites.
          We encourage you to review their privacy policies.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">8. Children's Privacy</h2>
        <p>
          Our services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children.
          If you believe we have collected data from a child, please contact us immediately.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">9. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We
          encourage you to review this policy regularly.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">10. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us:
        </p>
        <ul className="list-none pl-0">
          <li>Email: appliance-repair-experts@gmail.com</li>
          <li>Phone: +1 346-268-5088</li>
          <li>Address: Houston, Sugarland, West Airport 77498</li>
        </ul>
      </section>

      <p className="text-gray-600 mt-8">Thank you for trusting us.</p>
    </div>
  );
};

export default Privacy;
